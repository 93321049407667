import "./App.css";
import logo from "./assets/Paradox.PNG";
import myVideo from "./assets/clothbg2.mp4";

function App() {
  return (
    <div className="h-screen w-screen ">
      <div className="absolute inset-0 bg-black opacity-50 z-10 "></div>
      {/* Video autoPlay loop */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
      >
        <source src={myVideo} type="video/mp4"></source>
      </video>
      <div className="absolute inset-0 flex flex-col justify-center items-center z-10">
        {/* Logo */}
        <img
          src={logo}
          className="w-[50%] laptop:w-[565px] iphone:w-[85%] tablet:w-[565px] desktop:w-[50%] "
          alt="Logo"
        />
        {/* Content */}
        <div className=" mt-[-30px] iphone:text-sm iphone:py-8 tablet:text-md tablet:py-8 desktop:text-md desktop:py-4">
          {" "}
          {/* <h2 className="text-white text-center">03/12/2009 13:36pm 41* NYC</h2> */}
          <h2 className="text-white text-center">
            Our webstore is currently closed <br />
            03/12/0009 13:36pm 41* NYC
          </h2>
          <h2 className="text-white text-center pt-2">
            We'll be back sooner or later...
          </h2>
          <form className="flex flex-col items-center py-4">
            <input
              type="textarea"
              placeholder="enter email:"
              className=" placeholder:text-center rounded-none w-[275px]"
            />
            <div className=" py-4 text-white">
              <button className=" bg-transparent border-[2px] px-4 rounded-none">
                subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
